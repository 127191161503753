import React, { ReactChild } from "react";
import Img, { FluidObject } from "gatsby-image";

import "./styles/_PageHead.scss";

interface Props {
  children: ReactChild;
  containerStyle?: React.CSSProperties;
  backgroundImg?: FluidObject;
}

function PageHead({ children, containerStyle, backgroundImg }: Props) {
  return (
    <div className="head">
      <div className="head-container" style={{ ...containerStyle }}>
        {backgroundImg && (
          <Img
            className="backgroundImg"
            alt="head-banner"
            fluid={backgroundImg}
            style={{ height: "100%" }}
          />
        )}
        {children}
      </div>
    </div>
  );
}

export default PageHead;
